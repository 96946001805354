import axios from 'axios';


//axios.defaults.baseURL = 'https://sit-lp.stc.com.sa/api/api';
axios.defaults.baseURL = 'https://lp.stc.com.sa/api/api';
//axios.defaults.baseURL = 'https://sit-lp.stc.com.sa/api/api';
//axios.defaults.baseURL = 'https://sit-lp.stc.com.sa:17002/api-stc-landing-page/api';

let locale = 'en';

axios.interceptors.request.use(function (config) {
  locale = config.headers['Accept-Language'];
  return config;
}, function (error) {
  return Promise.reject(error);
});
 
axios.interceptors.response.use(
  (response) => {    
     return response;
  },
  (error) => {  
      if(error.response.data.code == 'INTERNAL_SERVER_ERROR' || error.response.data.code == 'ERROR_FROM_BACKEND'){
          error.response.data.message = locale == 'ar' ? 'عذرا هذه الخدمة غير متوفرة حاليا': 'Sorry This service is not available right now';
      }   
     return Promise.reject(error);
  }
);

export default axios;