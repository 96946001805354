import { useI18n } from "vue-i18n";

export default function useI18nLocalizer(){ 

    const { t } = useI18n({
        inheritLocale: true,
        useScope: "local",
      });
      // Something todo ..
      return { t };


}