<template>
  <main-layout></main-layout>
</template>

<style lang="scss">
@import "assets/css/stc-fonts.css";
@import "assets/css/stc-icons.css";
@import "assets/css/bootstrap.min.css";
@import "assets/css/animate.min.css";
@import "assets/css/stc-landing.scss";
</style>

<script>
import MainLayout from "@/shared-components/layout/main-layout";
export default {
  components: { MainLayout },
};
</script>

