<template>
  <header class="light">
    <div class="container" :v-model="$i18n.locale">
      <div class="heading">
        <a
          href="https://www.stc.com.sa"
          title="stc"
          class="logo"
          target="_blank"
        >
          <!--<img v-if="!isLight" src="@/assets/images/stc_logo.svg" />-->
          <img src="@/assets/images/stc_dark_id.svg" />
        </a>
        <button class="lang" v-on:click="changeLanguage()">
          <i class="icon-Internet" />
          <span>{{ t("lang") }}</span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import useI18nLocalizer from "@/hooks/i18nlocalizer";


export default {
  name: "stcHeader",
  props: ['isLight'],
  setup() {
    const { t } = useI18nLocalizer();
    return { t };
  },
  data() {
    return {
      lang: this.$i18n.locale,
    };
  },
  methods: {
    changeLanguage() {
      if (this.lang !== "ar") {
        this.lang = "ar";
      } else {
        this.lang = "en";
      }
      localStorage.setItem('lang', this.lang);
      this.$i18n.locale = this.lang;
      // window.location.reload();
    },
  },
};
</script>


<i18n>
{
  "en": {
    "lang": "العربية"
  },
  "ar": {
    "lang": "English"


  }
}
</i18n>

<style scoped lang="scss">
header {
  background: #4e008c;
  height: auto;
  padding: 12px;
  border: solid 0.3px #d5d5d5;
  position: sticky;
  top: 0;
  z-index: 999;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      img {
        max-height: 32px;
      }
    }

    .lang {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 13px;
      font-family: var(--stcLight);
      padding: 10px;
      outline: 0;
      display: flex;
      justify-content: flex-end;

      i {
        font-size: 18px;
        order: 1;
      }
      span {
        opacity: 0.6;
        margin: 2px 10px;
        transition: all ease-in-out 250ms;
      }

      &:hover {
        span {
          opacity: 1;
        }
      }
    }
  }

  &.light {
    background: #fff;
    .heading {
      .lang {
        color: var(--purple);
      }
    }
  }
}
</style>
