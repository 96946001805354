<template>
  <div class="layout" :style="styleLocalization()">
    <stc-header :isLight="isLight"></stc-header>
    <main>
      <router-view />
    </main>
    <stc-footer></stc-footer>
  </div>
</template>

<script>
import StcHeader from "@/shared-components/layout/stc-header";
import StcFooter from "@/shared-components/layout/stc-footer";
export default {
  name: "main-layout",
  components: { StcFooter, StcHeader },
  data() {
    return {
      isLight: true
    };
  },
  methods: {
    styleLocalization() {
      if (this.$i18n.locale === "ar") {
        return "direction: rtl;";
      }
      return "direction: ltr;";
    },
  },
};
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  main {
    flex: 1;
    position: relative;
  }
}
</style>
