import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
  // {
  //   path: '/',
  //   name: 'default',
  //   component: () => import('../views/landing/welcome-page.vue')
  // }

  // {
  //   path: '/amazon/:lang',
  //   name: 'amazon',
  //   component: () => import('../views/landing/amazon/amazon-prime.vue')
  // },
  {
    path: '/netflix/:lang',
    name: 'netflix',
    component: () => import('../views/landing/netflix/netflix.vue')
  },
  {
    path: '/pp/customer/new/:lang',
    name: 'Data SIM',
    component: () => import('../views/landing/data/prepaid/data-package.vue')
  },
  {
    path: '/ps/customer/new/:lang',
    name: 'Data SIM2',
    component: () => import('../views/landing/data/postpaid/data-package.vue')
  },
  // {
  //   path: '/in/:lang',
  //   name: 'i18n',
  //   component: () => import('../components/HelloI18n.vue')
  // },
  // {
  //   path: '/suspendedsim/',
  //   name: 'Suspended Sim',
  //   component: () => import('../views/static_pages/suspended-sim.vue')
  // },
  {
    path: '/pp/sim/consumption/:lang',
    name: 'Sim Consumption',
    component: () => import('../views/static_pages/prepaid-sim-consumption.vue')
  },
  {
    path: '/ps/sim/consumption/:lang',
    name: 'Sim Consumption2',
    component: () => import('../views/static_pages/postpaid-sim-consumption.vue')
  },
  {
    path: '/sim/validity/:lang',
    name: 'Sim Validity',
    component: () => import('../views/static_pages/sim-validity.vue')
  },
  {
    path: '/gaming/:lang',
    name: 'Gaming',
    component: () => import('../views/landing/gaming/game_ping')
  },
  // {
  //   path: '/ps/sim/billing/:lang',
  //   name: 'Sim Billing',
  //   component: () => import('../views/bills/postpaid/ps-bill.vue')
  // }
  {
    path: "/ps/sim/dataoffers/:lang",
    name: "Postpaid Offers",
    component: () => import("../views/landing/offers/PostpaidOffers.vue"),
  },
  {
    path: "/bill-info",
    name: "Bill Info",
    component: () => import("../views/landing/bill-payment/bill-payment.vue"),
  },
  {
    path: "/bill-payment",
    name: "Bill Payment",
    component: () => import("../views/landing/bill-payment-without-otp/bill-payment-without-otp.vue"),
  },
  {
    path: "/payment/confirmation",
    name: "Payment confirmation",
    component: () =>
      import(
        "../shared-components/digital-payment/components/payment-confirmation.vue"
      ),
  },
  {
    path: "/payment/auth",
    name: "Payment Auth",
    component: () =>
      import(
        "../shared-components/digital-payment/components/payment-auth.vue"
      ),
  },
  {
    path: "/payment/mpgsform",
    name: "Payment form",
    component: () =>
        import(
            "../shared-components/digital-payment/components/payment-mpgs-standalone.vue"
            ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../shared-components/404-revamp/404-revamp.vue"),
  },
  {
    path: "/sawa-recharge",
    name: "Sawa Recharge",
    component: () => import("../views/landing/sawa_recharge/sawa-recharge.vue")
  },
  {
    path: "/payment-links/:event",
    name: "Payment Links",
    component: () =>
      import("../views/landing/shared-payment/shared-payment.vue"),
  },
  {
    path: "/sawa-ziyara",
    name: "Sawa Ziyara",
    component: () => import("../views/landing/sawa-ziyara/sawa-ziyara.vue"),
  },
  {
    path: "/fingerprint",
    name: "Fingerprint",
    component: () => import("../views/landing/fingerprint/fingerprint.vue")
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: () =>
        import("../views/landing/forget-password/forget-password.vue"),
  },
  {
    path: "/sms-bill-payment",
    name: "SMS Bill Payment",
    component: () =>
        import("../views/landing/sms-bill-payment/sms-bill-payment.vue"),
  },
  {
    path: "/dcb-subscription",
    name: "DCB Subscription",
    component: () =>
        import("../views/landing/dcb/dcb.vue"),
  },
  {
    path: "/internal-dcb-subscription/:dcbType",
    name: "Internal Subscription",
    component: () =>
      import("../views/landing/internal-dcb/internal-dcb.vue"),
  },
  {
    path: "/nafath-otu",
    name: "Nafath OTU",
    component: () =>
      import("../views/landing/nafath/nafath.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
