<template>
  <footer>
    <div class="container">
      <div class="copyrights" :v-model="$i18n.locale">
        <a
          href="https://www.stc.com.sa"
          title="stc"
          class="logo"
          target="_blank"
        >
          <img src="@/assets/images/stc_logo.svg" />
        </a>
        <p>{{ t("copyrights") }} - {{ new Date().getFullYear() }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
import useI18nLocalizer from "@/hooks/i18nlocalizer";

export default {
  name: "stc-footer",
  setup() {
    const { t } = useI18nLocalizer();
    return { t };
  },
};
</script>
<i18n>
{
  "en": {
    "copyrights": "Copyright © stc. All rights reserved."
  },
  "ar": {
    "copyrights": " الحقوق محفوظه © stc "


  }
}
</i18n>


<style scoped lang="scss">
footer {
  background: var(--darkPurple);
  padding: 20px;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 999;
  // position: sticky;
  // width: 100%;
  // bottom: 0;
  .copyrights {
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      img {
        max-height: 22px;
      }
    }
    p {
      color: #fff;
      font-size: 13px;
      margin: 0 20px;
    }
  }
}
@media all and (max-width: 991px){
  footer{
    .copyrights {
      justify-content: center;
      flex-wrap: nowrap;
      .logo {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
