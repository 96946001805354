import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import axios from './plugins/axios';

// include bootstrap components to the page
import  "jquery";
import "@popperjs/core";
import "bootstrap";

createApp(App).use(i18n).use(router, axios).mount('#app')
